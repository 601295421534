@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

/* #main p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
} */

#main a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

#main.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  font-family: "Poppins", sans-serif;
  background: #fafafa;
  min-width: 250px;
  max-width: 250px;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  /* min-height: 100vh; */
  /* background: #7386d5; */
  background: #0b0c0e;
  color: #fff;
  transition: all 0.3s;
}

a[data-toggle="collapse"] {
  position: relative;
}

#main .dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

#sidebar .sidebar-header {
  padding: 20px;
  /* background: #6d7fcc; */
  background: #252629;
}

#sidebar ul.components {
  padding: 20px 0;
  /* border-bottom: 1px solid #47748b; */
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}
#sidebar ul li a:hover {
  /* color: #7386d5; */
  color: #0b0c0e;
  background: #fff;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  /* background: #6d7fcc; */
  background: #252629;
}

#main ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  /* background: #6d7fcc; */
  background: #252629;
}

#main ul ul a.active {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  /* background: #6d7fcc; */
  background: #b12a47;
}

@media (max-width: 767px) {
  #sidebar {
    margin-left: -250px;
  }
  #sidebar.active {
    margin-left: 0;
  }
}
#profileImage {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #512da8;
  font-size: 35px;
  color: #fff;
  text-align: center;
  line-height: 150px;
  margin: 0px auto;
}

#userProfile .userName {
  font-size: x-large;
  font-weight: bolder;
}
#userProfile .signout {
  font-size: large;
  font-weight: bolder;
}

.table-container {
  height: 70vh;
  overflow-y: scroll;
  margin-bottom: 30px;
  border-bottom: 1px solid #dee2e6;
}
