.checkbox-container {
  /* display: block; */
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #d0d0d0;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkbox-checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkbox-checkmark {
  background-color: #2196f3;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:disabled ~ .checkbox-checkmark {
  background-color: #dddddd;
}

/* Create the checkbox-checkmark/indicator (hidden when not checked) */
.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkbox-checkmark when checked */
.checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}

/* Style the checkbox-checkmark/indicator */
.checkbox-container .checkbox-checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
