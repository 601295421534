@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
body {
  background-color: #f1f2f6;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.height-79 {
  height: 79%;
}

.wrapper {
  min-height: 100vh;
  margin-bottom: -50px;
}

#main .wrapper {
  min-height: 85vh;
  margin-bottom: -50px;
}

@media (max-width: 767px) {
  #main .wrapper {
    min-height: 85vh;
    margin-bottom: -50px;
  }
}
.footer,
.push {
  padding-top: 0px;
  height: 50px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu-item {
  float: left;
  list-style-type: none;
}

.menu-item a {
  color: #9a9da0;
}

.menu-item a:hover {
  color: #cccecf;
}

.width-100 {
  width: 100%;
}

.float-right {
  float: right;
}

.dropdown-div {
  float: right;
  margin-right: 10px;
}

.wide-table table {
  width: 1600px;
}

.wide-table table > tbody > tr > td {
  height: 30px;
}

.edittickets {
  color: #0998a8 !important;
  font-weight: 800;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 0;
}

.ticket_item_selected {
  border: 2px solid #0998a8;
  border-radius: 0.5rem;
}

.btn-toolbar {
  justify-content: flex-end;
}

.table thead th {
  vertical-align: middle;
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #dee2e6;
}

.Backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  left: 0;
  top: 0;
}

/* .Fill {

}

.Loader,
.Loader:before,
.Loader:after {
  border-radius: 50%;
}

.Loader {
  color: #521751;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.Loader:before,
.Loader:after {
  position: absolute;
  content: '';
}

.Loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #f5f5f5;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.2em 5.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}

.Loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #f5f5f5;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  -webkit-transform-origin: 0px 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}

@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
} */

/* #main p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
} */

#main a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

#main.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  font-family: "Poppins", sans-serif;
  background: #fafafa;
  min-width: 250px;
  max-width: 250px;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  /* min-height: 100vh; */
  /* background: #7386d5; */
  background: #0b0c0e;
  color: #fff;
  transition: all 0.3s;
}

a[data-toggle="collapse"] {
  position: relative;
}

#main .dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

#sidebar .sidebar-header {
  padding: 20px;
  /* background: #6d7fcc; */
  background: #252629;
}

#sidebar ul.components {
  padding: 20px 0;
  /* border-bottom: 1px solid #47748b; */
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}
#sidebar ul li a:hover {
  /* color: #7386d5; */
  color: #0b0c0e;
  background: #fff;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  /* background: #6d7fcc; */
  background: #252629;
}

#main ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  /* background: #6d7fcc; */
  background: #252629;
}

#main ul ul a.active {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  /* background: #6d7fcc; */
  background: #b12a47;
}

@media (max-width: 767px) {
  #sidebar {
    margin-left: -250px;
  }
  #sidebar.active {
    margin-left: 0;
  }
}
#profileImage {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #512da8;
  font-size: 35px;
  color: #fff;
  text-align: center;
  line-height: 150px;
  margin: 0px auto;
}

#userProfile .userName {
  font-size: x-large;
  font-weight: bolder;
}
#userProfile .signout {
  font-size: large;
  font-weight: bolder;
}

.table-container {
  height: 70vh;
  overflow-y: scroll;
  margin-bottom: 30px;
  border-bottom: 1px solid #dee2e6;
}

.checkbox-container {
  /* display: block; */
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #d0d0d0;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkbox-checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkbox-checkmark {
  background-color: #2196f3;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:disabled ~ .checkbox-checkmark {
  background-color: #dddddd;
}

/* Create the checkbox-checkmark/indicator (hidden when not checked) */
.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkbox-checkmark when checked */
.checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}

/* Style the checkbox-checkmark/indicator */
.checkbox-container .checkbox-checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

body,
html {
  height: 100% !important;
}

.body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  background-color: #fefefe
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Is-Invalid {
  border-color: red;
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}

.bg-white {
  background-color: white;
}

.w-100-px {
  min-width: 100px;
}

.cell-text-unwrap .input-group {
  flex-wrap: nowrap !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.is-error {
  border-color: red;
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .sidebar {
    display: none;
  }
}

