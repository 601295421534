.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Is-Invalid {
  border-color: red;
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}

.bg-white {
  background-color: white;
}

.w-100-px {
  min-width: 100px;
}

.cell-text-unwrap .input-group {
  flex-wrap: nowrap !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.is-error {
  border-color: red;
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .sidebar {
    display: none;
  }
}
