body {
  background-color: #f1f2f6;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.height-79 {
  height: 79%;
}

.wrapper {
  min-height: 100vh;
  margin-bottom: -50px;
}

#main .wrapper {
  min-height: 85vh;
  margin-bottom: -50px;
}

@media (max-width: 767px) {
  #main .wrapper {
    min-height: 85vh;
    margin-bottom: -50px;
  }
}
.footer,
.push {
  padding-top: 0px;
  height: 50px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu-item {
  float: left;
  list-style-type: none;
}

.menu-item a {
  color: #9a9da0;
}

.menu-item a:hover {
  color: #cccecf;
}

.width-100 {
  width: 100%;
}

.float-right {
  float: right;
}

.dropdown-div {
  float: right;
  margin-right: 10px;
}

.wide-table table {
  width: 1600px;
}

.wide-table table > tbody > tr > td {
  height: 30px;
}

.edittickets {
  color: #0998a8 !important;
  font-weight: 800;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 0;
}

.ticket_item_selected {
  border: 2px solid #0998a8;
  border-radius: 0.5rem;
}

.btn-toolbar {
  justify-content: flex-end;
}

.table thead th {
  vertical-align: middle;
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #dee2e6;
}
